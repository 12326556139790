import React, { useState } from 'react';
import Hill1 from "./img/Hill1.jpeg";
import Hill2 from "./img/Hill2.png";
import Hill3 from "./img/Hill3.jpg";
import { Link } from "react-router-dom";


const Hill = () => {
  const [hoveredLink, setHoveredLink] = useState(null);
  return (
    <div><br /><br />
        <div className="style-F">
        <h6>Featured Tours</h6>
        </div>
        <h2>Hill Station Tour Packages</h2><br />
          <div className="container-A py-3 px-4">
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={Hill1} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>


<Link
        to="/shimla-manali-tour-from-delhi"
        onMouseEnter={() => setHoveredLink('shimla')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'shimla' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'shimla' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Unforgettable Shimla Manali Tour from Delhi</b></h3>
      </Link>            {/* <p className='text-start ms-0 '><b>Get compassionate and individualized care in your infertility matters from our devoted infertility care team. We are here to provide customized solutions with continuous support on your journey.</b></p> */}
        </div>
        <div className="container-F">
      <div className="item text-start">04 DAY</div>
      <div className="item text-center">12+</div>
      <div className="item text-end"></div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={Hill2} className="card-img-top" alt="" />
        <div className="card-body">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.0 Superb</p><br />
</div>
<Link
        to="/himachal-tour-from-delhi"
        onMouseEnter={() => setHoveredLink('himachal')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'himachal' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'himachal' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Enchanting Himachal Tour from Delhi</b></h3>
      </Link>        </div>
        <div className="container-F">
      <div className="item">10 DAY</div>
      <div className="item">12+</div>
      <div className="item"></div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={Hill3} className="card-img-top" alt="Dr. Shweta Goswami" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>

<Link
        to="/4-day-haridwar-rishikesh-mussoorie-tour"
        onMouseEnter={() => setHoveredLink('Mussoorie')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Mussoorie' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Mussoorie' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>
        4-Day Haridwar, Rishikesh, and Mussoorie Tour</b></h3>
      </Link>        </div>
        <div className="container-F">
      <div className="item">04 DAY</div>
      <div className="item">12+</div>
      <div className="item"></div>
    </div><br /><br />
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default Hill
