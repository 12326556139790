import React from 'react';
import "./PlanStat.css";

const PlanStat = () => {
  return (
    <div className="tour-container">
      <div className="style-F">
        <h6 className='text-white text-start'>Organize Your Journey with Us 😊</h6>
        <br />
        <div className="h">
          <h1 className='text-start text-white'>Excited for an unforgettable trip?</h1>
        </div>
      </div>
      <br />
      <a href="/plan-my-trip">
        <button className="button">BOOK TOUR NOW</button>
      </a>
    </div>
  );
}

export default PlanStat;
