import React, { useState } from 'react';
import Raj1 from "../components/img/Raj1.jpg";
import Raj2 from "../components/img/Raj2.jpg";
import Raj3 from "../components/img/Raj3.jpg";
import { Link } from "react-router-dom";
const RajasthanTourss = () => {
  const [hoveredLink, setHoveredLink] = useState(null);

  return (
    <div className="container-A py-3 px-4"><br /><h2><b>Rajasthan Tours</b></h2><br /><br /><br />
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-4 ">
        <div className="card-A">
          <img src={Raj1} className="card-img-top" alt="" />
          <div className="card-body">
          <br />
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
    <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 10 Superb</p><br />
  </div>
  <Link
        to="/jaipur-honeymoon-package"
        onMouseEnter={() => setHoveredLink('Golden')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Golden' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Golden' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start '><b>Jaipur Romantic Getaway <br /> Package</b></h3>
      </Link>  
      
          </div>
          <div className="container-F">
      <div className="item">4 DAY</div>
      <div className="item">12+</div>
      <div className="item">RAJASTHAN</div>
    </div><br /><br />
        </div>
        
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="card-A">
          <img src={Raj2} className="card-img-top" alt="" />
          <div className="card-body"><br />
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
    <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.0 Superb</p><br />
  </div>
  <Link
        to="/rajasthan-fort-and-palaces-tours"
        onMouseEnter={() => setHoveredLink('Forts')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Forts' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Forts' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-4 '><b>Rajasthan Forts and  Palaces Journey</b></h3>
      </Link>  
          </div>
          <div className="container-F">
      <div className="item">8 DAY</div>
      <div className="item">12+</div>
      <div className="item">RAJASTHAN</div>
    </div><br /><br />
        </div>
  
      </div>
      <div className="col-lg-4 col-md-1 mb-4">
        <div className="card-A">
          <img src={Raj3} className="card-img-top" alt="" />
          <div className="card-body"><br />
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
    <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
  </div>
  <Link
        to="/udaipur-and-jodhpur-tour"
        onMouseEnter={() => setHoveredLink('Jodhpur')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Jodhpur' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Jodhpur' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-4'><b>Udaipur and Jodhpur Expedition</b></h3>
      </Link>    
          </div>
          <div className="container-F">
      <div className="item">5 DAY</div>
      <div className="item">12+</div>
      <div className="item">RAJASTHAN</div>
    </div><br /><br />
        </div>
      </div>
    </div>
    
  
       
      </div>
  
  )
}

export default RajasthanTourss
