import React from 'react';
import './AboutBanner.css';
import { motion } from 'framer-motion';
// import { Link } from "react-router-dom";
// import CustomizedFertility from "./img/CustomizedFertility.webp";
// import ExpertTeam from "./img/ExpertTeam.jpg";
// import CuttingEdge from "./img/CuttingEdge.jpeg";
import AboutBannerr from "../components/img/AboutBannerr.jpg";
import AboutImage1 from "./img/AboutImage1.jpg"



const AboutBanner = () => {
  return (
    <div className="banner-container">
  <div>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        {/* <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> */}
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={AboutBannerr} className="d-block w-100 carousel-image" alt="..." />
            {/* <div className="carousel-caption d-none d-md-block">
              <h5>First slide label</h5>
              <p>Some representative placeholder content for the first slide.</p>
            </div> */}
          </div>
          {/* <div className="carousel-item">
            <img src="https://www.drkamleshtandonhospital.com/images/banner1.jpg" className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <h5>Second slide label</h5>
              <p>Some representative placeholder content for the second slide.</p>
            </div>
          </div> */}
        </div>
        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button> */}
        {/* <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button> */}
      </div>
    </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <motion.img 
              src={AboutImage1} 
              className="img-fluid" 
              alt="Your Image" 
              initial={{ x: '-100vw' }}
              animate={{ x: 0 }}
              transition={{ type: 'spring', stiffness: 50 }}
            />
          </div>
          <div className="col-md-6">
            <motion.div 
              className="content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 1.5 }}
            ><br />
               <div className="style-T">
            <p className='style-T  text-start  ms-2'>Discover Our Same Day Tour Packages</p>
            </div>
              <div className="yellow-line ms-2"></div> {/* Yellow underline */}
                <br /><br />
              <p className='text-start ms-2 text-white'>
              Start your journey with our Same Day Tour Packages for a hassle-free excursion to and from India. Booking your visit is quick, straightforward, and efficient online. Choose the additional items you need and begin planning your trip. We understand the value of your time and ensure deadlines are met with precision. Our dedicated team is here to provide seamless support and make your travel experience memorable.</p>
              <p className='text-start ms-2 text-white'><b>Wishing you a joyful and secure journey!!!</b></p>  
              <div><br /><br />
      <h5 className='text-start text-white'>Top-Quality Services</h5><div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: '98%' }}>      <span className="progress-bar-percentage">98%</span>
        </div>
      </div><br />
      <h5 className='text-start text-white'>
      Travel Experts</h5><div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: '100%' }}>
          <span className="progress-bar-text"></span>
          <span className="progress-bar-percentage">100%</span>
        </div>
      </div>
    </div>
    
    
               {/* <Link to="/contact-us" className="btn btn-warning ms-2 link-button">Contact Us</Link> */}
              {/* Add more content here */}
            </motion.div>
          </div>
        </div>
  


</div>
      </div>
   
  );
}

export default AboutBanner;
