import React, { useState } from 'react';
import SamDay1 from "../components/img/SamDay1.jpg";
import SamDay2 from "../components/img/SamDay2.jpg";
import SamDay3 from "../components/img/SamDay3.jpg";
import SamDay4 from "../components/img/SamDay4.jpg";
import SamDay5 from "../components/img/SamDay5.jpg";
import SamDay6 from "../components/img/SamDay6.jpg";
import SamDay7 from "../components/img/SamDay7.jpg";
import SamDay8 from "../components/img/SamDay8.jpg";
import SamDay9 from "../components/img/SamDay9.jpg";
import { Link } from "react-router-dom";

const SameDayTourr = () => {

  const [hoveredLink, setHoveredLink] = useState(null);
  return (
   
     
     <div className="container-A py-3 px-4"><br /><h2><b>Same Day Tours</b></h2><br /><br /><br />
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={SamDay1} className="card-img-top" alt="" />
        <div className="card-body">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/same-day-agra-by-car"
        onMouseEnter={() => setHoveredLink('agra')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'agra' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'agra' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start '><b>Agra Day Excursion by <br /> Car</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={SamDay2} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.0 Superb</p><br />
</div>
<Link
        to="/same-day-agra-tour-train"
        onMouseEnter={() => setHoveredLink('tour')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'tour' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'tour' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start '><b>Agra Day Journey by <br /> Train</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>

    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={SamDay3} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/same-day-agra-tour-train"
        onMouseEnter={() => setHoveredLink('sunrise')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'sunrise' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'sunrise' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start '><b>Taj Mahal Sunrise Excursion <br /> by Car</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={SamDay4} className="card-img-top" alt="" />
        <div className="card-body">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.0 Superb</p><br />
</div>
<Link
        to="/days-agra-overnight-by-car"
        onMouseEnter={() => setHoveredLink('overnight')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'overnight' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'overnight' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Agra 2-Day Overnight Adventure by Car</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">2 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={SamDay5} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 8.0 Superb</p><br />
</div>
<Link
        to="/tajmahal-sunrise-tour-and-delhi-walking"
        onMouseEnter={() => setHoveredLink('Walking')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Walking' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Walking' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Taj Mahal Dawn Visit and Delhi City Walk</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">DELHI/AGRA</div>
    </div><br /><br />
      </div>

    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={SamDay6} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/tajmahal-and-agra-fort-by-luxury-car"
        onMouseEnter={() => setHoveredLink('Mahal')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Mahal' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Mahal' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Luxury Car Tour of Taj Mahal and Agra Fort</b></h3>
      </Link>

        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={SamDay7} className="card-img-top" alt="" />
        <div className="card-body">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/agra-sunset-boat-ride-in-yamuna-river"
        onMouseEnter={() => setHoveredLink('boat')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'boat' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'boat' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Agra Sunset Boat Ride in Yamuna River</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">30 MINS</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={SamDay8} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/agra-local-city-sightseeing-tour"
        onMouseEnter={() => setHoveredLink('Local')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Local' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Local' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start'><b>Agra Local Sightseeing</b></h3><br />
      </Link>
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>

    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={SamDay9} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/delhi-agra-mathura-tour-2-days-by-car"
        onMouseEnter={() => setHoveredLink('Mathura')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Mathura' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Mathura' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Delhi Agra Mathura Tour - 2 Days By Car</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">2 DAY</div>
      <div className="item">12+</div>
      <div className="item">Delhi,Agra,Mathu</div>
    </div><br /><br />
      </div>
    </div>
  </div>
     
    </div>
  )
}

export default SameDayTourr
