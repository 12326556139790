import React from 'react'
import ContacctForm from '../components/ContacctForm';
import jodhpur1 from "../components/img/jodhpur1.jpg";
import jodhpur2 from "../components/img/jodhpur2.jpg";
import jodhpur3 from "../components/img/jodhpur3.jpg";
const UdaipurAndJodhpurTour = () => {
  return (
    <div className="tour-details-container"><br />
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          
           <div className="carousel-inner">
             <div className="carousel-item active">
               <img src={jodhpur1} className="d-block w-100 carousel-image" alt="..." />
               <div className="overlayy"></div>
               <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                
               </div>
             </div>
             <div className="carousel-item">
               <img src={jodhpur2} className="d-block w-100 carousel-image" alt="..." />
               <div className="overlayy"></div>
               <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                
               </div>
             </div>
             <div className="carousel-item">
               <img src={jodhpur3} className="d-block w-100 carousel-image" alt="..." />
               <div className="overlayy"></div>
               <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                
               </div>
             </div>
           </div>
           <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
             <span className="carousel-control-prev-icon" aria-hidden="true"></span>
             <span className="visually-hidden">Previous</span>
           </button>
           <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
             <span className="carousel-control-next-icon" aria-hidden="true"></span>
             <span className="visually-hidden">Next</span>
           </button>
         </div>
         <div className="tour-details">
           <h3 className="tour-title">Udaipur And Jodhpur Expedition</h3>
           <h5 className='text-start ms-2'>$ /Person</h5><br />
           <div className="tour-info-container">
             <div className="tour-info">
               <div className="info-item">
                 <span className="icon">
                   <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" viewBox="0 0 24 24">
                     <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                   </svg>
                 </span>
                 <span className="label">Duration<br />5 Day</span>
               </div>
               <div className="info-item">
                 <span className="icon">
                   <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" viewBox="0 0 24 24">
                     <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                   </svg>
                 </span>
                 <span className="label">Min Age <br />12 +</span>
               </div>
               <div className="info-item">
                 <span className="icon">
                   <svg
                     className="w-6 h-6"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     width="35"
                     height="30"
                     fill="none"
                     viewBox="0 0 25 32"
                     style={{ fill: 'none', stroke: '#7CA24A', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round' }}
                   >
                     <path d="M17.542,8.382L2.958,4.902l-1.25,1.26c-0.18,0.17-0.14,0.45,0.07,0.58l11.083,6.465"/>
                     <path d="M17.884,17.581l7.374,12.642c0.13,0.209,0.41,0.25,0.58,0.07l1.26-1.25l-3.903-16.359"/>
                     <path d="M9.134,19.857l-6.336-0.715l-1.19,1.189c-0.18,0.18-0.13,0.48,0.09,0.6l3.787,1.975"/>
                     <path d="M8.109,24.625l2.958,5.677c0.12,0.221,0.42,0.271,0.6,0.091l1.19-1.19l-0.715-6.333"/>
                     <path d="M7.328,24.673l0.4-0.011c0.12-0.01,2.81-0.14,4.88-2.22c0.63-0.58,14.51-13.32,15.99-14.811c2.2-2.2,2.15-5.149,1.54-5.77c-0.61-0.61-3.58-0.66-5.77,1.54c-1.5,1.5-14.23,15.359-14.82,16c-0.644,0.649-1.104,1.354-1.43,2.024"/>
                     <line x1="10.5" x2="4" y1="21.5" y2="28"/>
                     <path d="M27.498,3.502c0.552,0,1,0.448,1,1"/>
                   </svg>
                 </span>
                 <span className="label text-start">Tour Type<br /> Fun
                 </span>
               </div>
               <div className="info-item">
                 <span className="icon">
                   <svg
                     className="w-6 h-6"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     width="30"
                     height="30"
                     fill="none"
                     viewBox="0 0 20 20"
                     style={{ fill: '#7CA24A', fillRule: 'evenodd', clipRule: 'evenodd' }}
                   >
                     <path d="M9.3,19.7C8.5,18.9,2,12.2,2,8c0-4.4,3.6-8,8-8s8,3.6,8,8c0,4.2-6.5,10.9-7.3,11.7C10.3,20.1,9.7,20.1,9.3,19.7z M10,2 C6.7,2,4,4.7,4,8c0,2.5,3.8,7.2,6,9.5c2.2-2.3,6-7,6-9.5C16,4.7,13.3,2,10,2z"/>
                     <circle cx="10" cy="8" r="2"/>
                   </svg>
                 </span>
                 <span className="label">Location <br />Rajasthan</span>
               </div>
             </div>
           </div>
         </div>
         <div>
           <br />
           {/* Any additional content you add here will not have the background color */}
   
           <p className='text-start texp ms-4'> <svg className="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={25} height={30} fill="none" viewBox="0 0 24 24">
                     <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                   </svg> Posted 2 days ago |  <i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i> <i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1 " aria-hidden="true" style={{ color: 'gold' }}> </i>  9.5 Superb </p>
         </div><hr />
         <div className="overview-contact-wrapper">
           <div className="overview-container">
             {/* <h1 className="overvieyw-title text-start ms-3">Overview</h1>
             <p className="overview-text text-start ms-3"><br /><br />
             Rajasthan is famed across the globe for the lifestyle of the Maharajas and it’s magnificent forts and palaces. The present tour package gives you the comforts of exploring the wonders of Rajasthan forts such as Amber Fort, Mehrangarh fort, Chittoregarh fort etc. This is 8 days Rajasthan tour by car with driver. You are provided with the local tour guides at all destinations.</p> */}
              <div className="include-exclude-wrapper">
         <br /><h2 className='text-start'>Include/Exclude</h2><br />
         <div className="include-exclude-container">
           <ul className="include-list">
             <li>
               <span role="img" aria-label="Check">✅</span>
               Ac luxury Car 
             </li>
             <li>
               <span role="img" aria-label="Check">✅</span>
               1 Meal Per Day
             </li>
             <li>
               <span role="img" aria-label="Check">✅</span>
               Govt. Approved Tour guide
             </li>
             <li>
               <span role="img" aria-label="Check">✅</span>
               Visit 7 Best Places in the City With Group
             </li>
             {/* <li>
               <span role="img" aria-label="Check">✅</span>Visit 7 Best Places in the City With Group
             </li> */}
           </ul>
           <ul className="exclude-list">
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Additional Services
             </li>
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Insurance
             </li>
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Food & Drinks
             </li>
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Tickets
             </li>
           </ul>
         </div>
         <hr /><br /><br /><h2 className='text-start ms-2'>Tour Plan</h2>
         <div className="text-box"><br /><br />
         {/* <p className='text-start ms-3'>The Golden Triangle Tour is among the number one in style excursions in the Republic of India as well as it's no surprise. The tour takes you to Delhi, Agra, and Jaipur, a variety of the number one type of traveler locations in the Republic of India.</p><br /> */}

           {/* <h5 className='text-start ms-3'>Travel plan Highlights</h5><br /> */}
           <h4 className='text-start ms-3'>Day 1:Udaipur Arrival</h4>

           {/* <h5 className='text-start ms-3'>Pickup from the Delhi area (including Gurgaon, Noida, Faridabad, and Ghaziabad) at around 3:00 AM.</h5><br /> */}
           <p className='text-start ms-3'>Upon you’re reaching at Udaipur airport, you will be received by our company representative and transferred to your pre-booked hotel in the city of lakes. After the refreshment you will be leaving with your luxury car to the visit of Lake Pichola. The lake Pichola is the prime attraction of Udaipur city. Here you will admire the beauty of this beautiful lake by enjoying a boat ride where on one side a royal city palace stands which provides a perfect view for the photography. Your evening is free to explore in the beautiful markets and to enjoy the nightlife of this romantic city. Stay overnight at hotel.</p>
          <br /><br /> <h4 className='text-start ms-3'>Day 02:- Udaipur Sightseeing</h4>
          <p className='text-start ms-3'>After breakfast in the morning you will be leaving for the sightseeing of Udaipur city, visit the famous Jagdish Temple, City palace and museum, Jag Mandir , Saheliyon ki baari and Bhariya lok kala bhawan. Your evening is free for leisure. Stay overnight at hotel.</p>
          <br /><br /> <h4 className='text-start ms-3'>Day 03:- Udaipur to Jodhpur</h4>
          <p className='text-start ms-3'>After the sumptuous breakfast you will be leaving for the desert city of Rajasthan known as Jodhpur. En-route you will stop for watching the Ranakpur Jain temple dedicated to Jain Teerthankara. This beautiful temple is one of the major pilgrimage centre of Jainism and famous for its beautiful craftsmanship and intricate design and art work. Upon reaching Jodhpur you will be straight leaving to enjoy the visit of Osian which is an oasis in Thar Desert. Here you will participate in many adventurous activities such as camel safari, jeep safari etc.

Also enjoy the musical performances and dances of locals. This place is housing so many Hindu and Jain temples such as Sachiyata mata temple, Sun temple and Mahaveer Jain temple. Later you will come back to your hotel in Jodhpur for overnight stay.</p>
          <br /><br /> <h4 className='text-start ms-3'>Day 04:- Jodhpur Sightseeing</h4>
          <p className='text-start ms-3'>This day is dedicated to explore the historical building of Jodhpur city such as Mehrangarh fort, Jaswant Thada a memorial of maharaja Jaswant singh, Ummaid Bhawan palace a heritage hotel and royal residence. Your evening is pre decided to explore the clock tower market which is</p>
          <br /><br /> <h4 className='text-start ms-3'>Day 05:- Jodhpur Departure</h4>
          <p className='text-start ms-3'>Enjoy the luxury breakfast, and then you will be transfer to Jodhpur airport/ railway station to catch your flight/ train for the onwards journey. Here the tour ends.</p>
          {/* <br /><br /> <h4 className='text-start ms-3'>Day 6: Mehrangarh fort (Jodhpur)</h4>
          <p className='text-start ms-3'>Enjoy the breakfast and then you will head towards the Jodhpur city. It will take 5 hours to reach Jodhpur from Udiapur. After the refreshment you will head towards the visit of the Mehrangarh Fort which is spread over in area of 1200 acres and located on a height of 122 meter. This fort has been built by Rao Jodha in year 1459. Afterwards you will proceed to visit the royal castle Umaid Bhawan Palace which is a modern residence of maharaja. Afterwards come back to your hotel for overnight stay.</p>
          <br /><br /> <h4 className='text-start ms-3'>Day 7: Jaisalmer Fort (Jaisalmer)</h4>
          <p className='text-start ms-3'>After breakfast at hotel, your drive starts for Jaisalmer which is famous for its unique desert land in all over the world. Check in to your hotel and take refreshment. In the afternoon you will head towards the visit of the largest fort of the world known as Jaisalmer fort. This fort is also famous by the name of golden fort or sonar qila. This fort is also known as one of the few old living fort where people still resides in large gathering. This is the second oldest fort of rajasthan built in1156 AD by Rajput Rawal. This fort has been built bay lime stone and sandstone looking like golden fort when sun light falls on it. Come back to your hotel for overnight stay.</p>
          <br /><br /> <h4 className='text-start ms-3'>Day 8: Departure (Jodhpur)</h4>
          <p className='text-start ms-3'>Early morning, after breakfast, you drive starts for Jodhpur which is around 275 km from Jodhpur and it will take 6 hour to reach. Upon reaching Jodhpur catch your flight for next destination.</p> */}
          {/* <br /><br /> <h4 className='text-start ms-3'>12.30 p.m. - Agra Fort</h4>
          <p className='text-start ms-3'>The Brilliant Reign Of sultan Akbar crafted Agra Fort in 1565 A.D. Our guide explains you all about this place.</p>
          <br /><br /> <h4 className='text-start ms-3'>14:00 p.m. - Lunch</h4> */}
          {/* <p className='text-start ms-5 text-black'>* Sunrise or sunset Taj Mahal boat ride on the Yamuna River from Agra</p>
          <p className='text-start ms-5 text-black'>* Enjoy unobstructed views of the Taj Mahal and avoid huge crowds</p>
          <p className='text-start ms-5 text-black'>* See the monument at its most magical: when the sun rises or sets</p>
          <p className='text-start ms-5 text-black'>* Choose from two departure times and receive personalized attention from a guide.</p><br />
           */}
          {/* <p className='text-start ms-3'>Following a visit to the Agra Fort, It's time to eat lunch at a highly regarded steakhouse and sample Mughal recipes.</p>
          <br /><br /> <h4 className='text-start ms-3'>15:00 p.m. - Baby Taj</h4>
          <p className='text-start ms-3'>It's time to go and see Baby Taj one that our tourist guide describes in detail. It's optional visit.</p>
          <br /><br /> <h4 className='text-start ms-3'>16:00 p.m. - Mehtab Bagh</h4>
          <p className='text-start ms-3'>Upon having visited the Baby Taj simply continue to Mehtab Bagh, from where you would see the Taj Mahal's backside with the River Yamuna. Take some snaps to retain your memorable moments.</p>
          <br /><br /> <h4 className='text-start ms-3'>17:00 p.m. - Departure from Agra Cantt Railway Station</h4>
          <p className='text-start ms-3'>Upon having visited Agra, it's the moment to say good-bye with fantastic memories. Our motorist will drop you off at Agra Cantt Railway Station. There from, board your train, which departs at 17:50 p.m. and arrives at H Nizamuddin Railway Station at 19:30 p.m. Our motorist will therefore grant you access from the station and drop you off towards your hotel. The tour concludes here with wonderful memories.</p> */}

          <br /><br />
         </div>
         
       </div>
       
           </div>
           <div className="contact-form-wrapper">
             <ContacctForm />
           </div>
           
         </div>
         <div className="review-scores-container ms-2"><h2 className='text-start ms-2'>Review Scores</h2>
      <div className="review-scores">
        <div className="review-scores__left">
          <div className="review-scores__rating">8.0</div>
          <p className='text-start text'>   ⭐Superb </p>
        </div>
        <div className="review-scores__right">
          <div className="review-scores__row">
            <div className="review-scores__category">Services</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '50%' }} />
            </div>
            <div className="review-scores__percentage">50%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Locations</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '87%' }} />
            </div>
            <div className="review-scores__percentage">87%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Amenities</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '77%' }} />
            </div>
            <div className="review-scores__percentage">77%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Prices</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '69%' }} />
            </div>
            <div className="review-scores__percentage">69%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Food</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '40%' }} />
            </div>
            <div className="review-scores__percentage">40%</div>
          </div>
        </div>
      </div>
      
    </div>
    <br /><br /><h2 className='text-start ms-4'>Tour Plan</h2>
    <div className="map-container">
    <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.99973450298!2d75.65047228361074!3d26.88514167956319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1632481391151!5m2!1sen!2sin"
  className="tour-details-two__location-map"
  allowFullScreen=""
  style={{ width: '100%', height: '500px', border: '0' }}
  loading="lazy"
  title="Delhi-Agra Route Map"
></iframe>

    </div> 
       </div>
  )
}

export default UdaipurAndJodhpurTour
