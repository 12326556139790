import React from 'react'
import Banner from './Banner'
import Taj1Banner from "../components/Taj1Banner";
import FeaturedTour from './FeaturedTour';
import OverNight from './OverNight';
import Hill from './Hill';
import PlanStat from './PlanStat';
import TajVideo from './TajVideo';
import TourTypes from './TourTypes';
import TripAdvisorWidget from './TripAdvisorWidget';


// import Carousel from './Carousel';






const Home = () => {
  return (
    <div>
       <br />
        <Banner/> <br /><br />
       
        <Taj1Banner/><br /><br />
        <FeaturedTour/>
        <OverNight/>
        <Hill/>
        <PlanStat/><br />
        <TajVideo/><br /><br /><br />
        <TourTypes/><br /><br />
       <br /><br />
        <TripAdvisorWidget/>
     
         <br /><br />
       
      
    </div>
  )
}

export default Home
