import React from 'react'
import ContacctForm from '../components/ContacctForm';
import Train1 from "../components/img/Train1.jpg";
import Train2 from "../components/img/Train2.jpg";
import Train3 from "../components/img/Train3.jpg";
const TajMahalSunrise = () => {
  return (
    <div className="tour-details-container"><br />
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          
           <div className="carousel-inner">
             <div className="carousel-item active">
               <img src={Train1} className="d-block w-100 carousel-image" alt="..." />
               <div className="overlayy"></div>
               <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                
               </div>
             </div>
             <div className="carousel-item">
               <img src={Train2} className="d-block w-100 carousel-image" alt="..." />
               <div className="overlayy"></div>
               <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                
               </div>
             </div>
             <div className="carousel-item">
               <img src={Train3} className="d-block w-100 carousel-image" alt="..." />
               <div className="overlayy"></div>
               <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                
               </div>
             </div>
           </div>
           <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
             <span className="carousel-control-prev-icon" aria-hidden="true"></span>
             <span className="visually-hidden">Previous</span>
           </button>
           <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
             <span className="carousel-control-next-icon" aria-hidden="true"></span>
             <span className="visually-hidden">Next</span>
           </button>
         </div>
         <div className="tour-details">
           <h2 className="tour-title">Taj Mahal Dawn Visit and Delhi City Walk</h2>
           <h5 className='text-start ms-2'>$ /Person</h5><br />
           <div className="tour-info-container">
             <div className="tour-info">
               <div className="info-item">
                 <span className="icon">
                   <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" viewBox="0 0 24 24">
                     <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                   </svg>
                 </span>
                 <span className="label">Duration<br />1 Day</span>
               </div>
               <div className="info-item">
                 <span className="icon">
                   <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" viewBox="0 0 24 24">
                     <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                   </svg>
                 </span>
                 <span className="label">Min Age <br />12 +</span>
               </div>
               <div className="info-item">
                 <span className="icon">
                   <svg
                     className="w-6 h-6"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     width="35"
                     height="30"
                     fill="none"
                     viewBox="0 0 25 32"
                     style={{ fill: 'none', stroke: '#7CA24A', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round' }}
                   >
                     <path d="M17.542,8.382L2.958,4.902l-1.25,1.26c-0.18,0.17-0.14,0.45,0.07,0.58l11.083,6.465"/>
                     <path d="M17.884,17.581l7.374,12.642c0.13,0.209,0.41,0.25,0.58,0.07l1.26-1.25l-3.903-16.359"/>
                     <path d="M9.134,19.857l-6.336-0.715l-1.19,1.189c-0.18,0.18-0.13,0.48,0.09,0.6l3.787,1.975"/>
                     <path d="M8.109,24.625l2.958,5.677c0.12,0.221,0.42,0.271,0.6,0.091l1.19-1.19l-0.715-6.333"/>
                     <path d="M7.328,24.673l0.4-0.011c0.12-0.01,2.81-0.14,4.88-2.22c0.63-0.58,14.51-13.32,15.99-14.811c2.2-2.2,2.15-5.149,1.54-5.77c-0.61-0.61-3.58-0.66-5.77,1.54c-1.5,1.5-14.23,15.359-14.82,16c-0.644,0.649-1.104,1.354-1.43,2.024"/>
                     <line x1="10.5" x2="4" y1="21.5" y2="28"/>
                     <path d="M27.498,3.502c0.552,0,1,0.448,1,1"/>
                   </svg>
                 </span>
                 <span className="label text-start">Tour Type<br />Fun
                 </span>
               </div>
               <div className="info-item">
                 <span className="icon">
                   <svg
                     className="w-6 h-6"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     width="30"
                     height="30"
                     fill="none"
                     viewBox="0 0 20 20"
                     style={{ fill: '#7CA24A', fillRule: 'evenodd', clipRule: 'evenodd' }}
                   >
                     <path d="M9.3,19.7C8.5,18.9,2,12.2,2,8c0-4.4,3.6-8,8-8s8,3.6,8,8c0,4.2-6.5,10.9-7.3,11.7C10.3,20.1,9.7,20.1,9.3,19.7z M10,2 C6.7,2,4,4.7,4,8c0,2.5,3.8,7.2,6,9.5c2.2-2.3,6-7,6-9.5C16,4.7,13.3,2,10,2z"/>
                     <circle cx="10" cy="8" r="2"/>
                   </svg>
                 </span>
                 <span className="label">Location <br />Delhi/Agra</span>
               </div>
             </div>
           </div>
         </div>
         <div>
           <br />
           {/* Any additional content you add here will not have the background color */}
   
           <p className='text-start texp ms-4'> <svg className="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={25} height={30} fill="none" viewBox="0 0 24 24">
                     <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                   </svg> Posted 2 days ago |  <i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i> <i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1 " aria-hidden="true" style={{ color: 'gold' }}> </i>  8.0 Superb </p>
         </div><hr />
         <div className="overview-contact-wrapper">
           <div className="overview-container">
             <h1 className="overvieyw-title text-start ms-3">Overview</h1>
             <p className="overview-text text-start ms-3"><br /><br />
             Combine two of India’s must-do experiences in a single exclusive tour: a sunrise visit to the Taj Mahal and a walking exploration of Old Delhi. Booking these activities together maximizes your time, allowing you to enjoy both without the hassle of separate arrangements. With a private guide, you'll receive personalized commentary tailored to your interests and travel at your own pace. Experience the breathtaking beauty of the Taj Mahal at dawn and immerse yourself in the vibrant culture of Old Delhi, all in one unforgettable journey.

</p> <div className="include-exclude-wrapper">
         <br /><h2 className='text-start'>Include/Exclude</h2><br />
         <div className="include-exclude-container">
           <ul className="include-list">
             <li>
               <span role="img" aria-label="Check">✅</span>
               Ac luxury Car
             </li>
             <li>
               <span role="img" aria-label="Check">✅</span>
               1 Meal Per Day
             </li>
             <li>
               <span role="img" aria-label="Check">✅</span>
               Govt. Approved Tour guide
             </li>
             <li>
               <span role="img" aria-label="Check">✅</span>
               Rickshaw Ride in Old Delhi
             </li>
             <li>
               <span role="img" aria-label="Check">✅</span>Camera Fees at Jama Masjid
             </li>
           </ul>
           <ul className="exclude-list">
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Additional Services
             </li>
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Insurance
             </li>
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Food & Drinks
             </li>
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Tickets
             </li>
           </ul>
         </div>
         <hr /><br /><br /><h2 className='text-start ms-2'>Tour Plan</h2>
         <div className="text-box"><br /><br />
           <h4 className='text-start ms-3'>03:30 AM: Pick up from your hotel.</h4>
           {/* <h5 className='text-start ms-3'>Pickup from the Delhi area (including Gurgaon, Noida, Faridabad, and Ghaziabad) at around 3:00 AM.</h5><br /> */}
           <p className='text-start ms-3'>Grab from your resort in Delhi/ Gurgaon/ Noida and likewise from Airport. Drive to Agra enjoys 3 hrs of a quiet ride in your very own personal excellent automobile.</p>
          <br /><br /> <h4 className='text-start ms-3'>06:00 AM: Explore Taj Mahal Sunrise View.</h4>
          <p className='text-start ms-3'>You will arrive in Agra, meet your tour guide, and afterward, you will definitely visit the Taj Mahal. Check out the Taj Mahal daybreak viewpoint. Spend around 2 hrs in the mausoleum.</p>
          <br /><br /> <h4 className='text-start ms-3'>08:00 AM: Explore Agra Fort.</h4>
          <p className='text-start ms-3'>After visiting the Taj Mahal your next monuments will be Agra Fort which was built by Great Mughal Emperor "Akbar". Spend around 1 hour in the building.</p>
          <br /><br /> <h4 className='text-start ms-3'>09:00 AM: Breakfast at the Restaurant.</h4>
          <p className='text-start ms-3'>After visiting Agra Fort you will proceed to Restaurant for breakfast / heavy meal. Spend some time in Restaurant and Relax.</p>
          <br /><br /> <h4 className='text-start ms-3'>09:30 AM: Visit Mehtab Bagh.</h4>
          {/* <p className='text-start ms-5 text-black'>* Sunrise or sunset Taj Mahal boat ride on the Yamuna River from Agra</p>
          <p className='text-start ms-5 text-black'>* Enjoy unobstructed views of the Taj Mahal and avoid huge crowds</p>
          <p className='text-start ms-5 text-black'>* See the monument at its most magical: when the sun rises or sets</p>
          <p className='text-start ms-5 text-black'>* Choose from two departure times and receive personalized attention from a guide.</p><br />
           */}
          <p className='text-start ms-3'>If you are not in a hurry and also want to explore another site then you can visit Mehtab Bagh. Explore the rear view of the Taj Mahal shadowing on the river Yamuna.</p>
          <br /><br /> <h4 className='text-start ms-3'>10:00 AM:</h4>
          <p className='text-start ms-3'>Drive Back to Delhi.</p>
          <br /><br /> <h4 className='text-start ms-3'>01:00 PM: Arrive Back in Delhi.</h4>
          <p className='text-start ms-3'>Arrive back in Delhi, the tour guide will be available here, have some lunch and proceed to visit Old Delhi.</p>
          <br /><br /> <h4 className='text-start ms-3'>02:00 PM:</h4>
          <p className='text-start ms-3'>You will visit Jama Masjid in Delhi.</p>
          <br /><br /> <h4 className='text-start ms-3'>03:00 PM: Rickshaw Ride in Chandni Chowk.</h4>
          <p className='text-start ms-3'>Now you will visit the Chandni Chowk market with Rickshaw Ride among the busy and narrow streets of Old Delhi.</p>
          <br /><br /> <h4 className='text-start ms-3'>04:00 PM: Red Fort View.</h4>
          <p className='text-start ms-3'>Get the View of Red Fort from outside, a guide will give you briefly on the significant importance of this monument.</p>
          <br /><br /> <h4 className='text-start ms-3'>05:00 PM:Drop Back to your Hotel.</h4>
          <p className='text-start ms-3'>Meeting Location/Transit Details Pick upcoming from your accommodation in Delhi/ Gurgaon/ Noida and additionally coming from Airport.</p>

          
         </div>
         
       </div>
       
           </div>
           <div className="contact-form-wrapper">
             <ContacctForm />
           </div>
           
         </div>
         <div className="review-scores-container ms-2"><h2 className='text-start ms-2'>Review Scores</h2>
      <div className="review-scores">
        <div className="review-scores__left">
          <div className="review-scores__rating">8.0</div>
          <p className='text-start text'>   ⭐Superb </p>
        </div>
        <div className="review-scores__right">
          <div className="review-scores__row">
            <div className="review-scores__category">Services</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '50%' }} />
            </div>
            <div className="review-scores__percentage">50%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Locations</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '87%' }} />
            </div>
            <div className="review-scores__percentage">87%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Amenities</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '77%' }} />
            </div>
            <div className="review-scores__percentage">77%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Prices</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '69%' }} />
            </div>
            <div className="review-scores__percentage">69%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Food</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '40%' }} />
            </div>
            <div className="review-scores__percentage">40%</div>
          </div>
        </div>
      </div>
      
    </div>
    <br /><br /><h2 className='text-start ms-4'>Tour Plan</h2>
    <div className="map-container">
    <iframe
  src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d112046.76867161422!2d77.10752704959401!3d28.645896645236768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sDelhi-Agra%20Route!5e0!3m2!1sen!2sin!4v1632382376955!5m2!1sen!2sin"
  className="tour-details-two__location-map"
  allowFullScreen=""
  style={{ width: '100%', height: '500px', border: '0' }}
  loading="lazy"
  title="Delhi-Agra Route Map"
></iframe>

    </div> 
       </div>
  )
}

export default TajMahalSunrise
