import React, { useEffect } from 'react';
import './TripAdvisorWidget.css'; // Import the CSS for styling

const TripAdvisorWidget = () => {
  useEffect(() => {
    // Create and append the script element for the widget
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.dataset.useServiceCore = true;
    script.defer = true;

    // Append script to body
    document.body.appendChild(script);

    // Function to handle the widget load
    const onScriptLoad = () => {
      console.log('TripAdvisor widget script loaded');
    };

    // Check if script loaded successfully
    script.addEventListener('load', onScriptLoad);

    // Clean up script and event listeners when component unmounts
    return () => {
      document.body.removeChild(script);
      script.removeEventListener('load', onScriptLoad);
    };
  }, []);

  return (
    <div className="trip-advisor-widget-container">
      <div className="elfsight-app-e7237de2-d50d-4bce-9b0b-11ca95db146c" data-elfsight-app-lazy></div>
    </div>
  );
};

export default TripAdvisorWidget;
