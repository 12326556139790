import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';


function PlanContact() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { ref: formRef, inView: formInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch('https://formspree.io/f/xeojgvqg', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-form-container py-5">
      <div className="contact-info">
        
        <p className='text-start ms-2'>𝓒𝓸𝓷𝓷𝓮𝓬𝓽 𝔀𝓲𝓽𝓱 𝓞𝓾𝓻 𝓣𝓮𝓪𝓶 <i className="fa fa-comments" aria-hidden="true"></i></p>
        
        <h1 className='text-start ms-2'>Submit Your Details to Plan a Custom Tour 😎</h1><br />
        <div className="social-icons ms-2">
          <a href="https://www.facebook.com/profile.php?id=61563812788901" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
          <a href="https://www.instagram.com/budgettajtour/" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram ms-2"></i></a>
          {/* <a href="https://www.youtube.com/@dr.vaishalitandon-oz6of" aria-label="YouTube" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube ms-2"></i></a>
          <a href="https://www.google.com/search?kgmid=/g/11vp_f5w43&hl=en-IN&q=Dr.+Vaishali+Tandon&kgs=0b01d5c6f42a5fb0&shndl=30&shem=lrnole,ssic&source=sh/x/loc/osrp/m5/4#lrd=0x3974779af6cb7cef:0xe02e9fe2e36d2145,1,,,," aria-label="Google" target="_blank" rel="noopener noreferrer"><i className="fab fa-google ms-2"></i></a> */}
        </div>
      </div>
      <div className="contact-form">
        {submitted ? (
          <p className="success">Thank you for your message. We will get back to you soon.</p>
        ) : (
          <form ref={formRef} className={`form ${formInView ? 'animate' : ''}`} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <input type="text" placeholder="Your name" {...register("name", { required: 'Name is required' })} />
              {errors.name && <p className="error">{errors.name.message}</p>}
            </div>
            <div className="form-group">
              <input type="email" placeholder="Email address" {...register("email", { required: 'Email is required', pattern: { value: /^\S+@\S+$/i, message: 'Please enter a valid email address' } })} />
              {errors.email && <p className="error">{errors.email.message}</p>}
            </div>
            <div className="form-group">
              <input type="text" placeholder="Contact" {...register("contact", { required: 'Contact is required' })} />
              {errors.contact && <p className="error">{errors.contact.message}</p>}
            </div>
            <div className="form-group">
              <input type="text" placeholder="Present Country Residence" {...register("country", { required: 'Present Country Residence is required' })} />
              {errors.country && <p className="error">{errors.country.message}</p>}
            </div>
            <div className="form-group">
              <input type="text" placeholder="Tour Start/Arrival Date" {...register("start", { required: 'Tour Start/Arrival Date is required' })} />
              {errors.start && <p className="error">{errors.start.message}</p>}
            </div>
            <div className="form-group">
              <input type="text" placeholder="Tour End/Departure Date" {...register("end", { required: 'Tour End/Departure Date is required' })} />
              {errors.end && <p className="error">{errors.end.message}</p>}
            </div>
            <div className="form-group">
              <textarea placeholder="Message" {...register("message", { required: 'Message is required' })}></textarea>
              {errors.message && <p className="error">{errors.message.message}</p>}
            </div>
            <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? <span className="spinner"></span> : 'SEND A MESSAGE'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default PlanContact;
