import React, { useState } from 'react';
import Salogo from "../components/img/Salogo.png";
import './Navbar.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import About from './About';
import Home from './Home';
import PlanMyTrip from './PlanMyTrip';
import ContactUs from './ContactUs';
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop component
import SameDayTour from '../droppages/SameDayTour';
import GoldenTriangleTours from '../droppages/GoldenTriangleTours';
import RajasthanTours from '../droppages/RajasthanTours';
import SameDayAgraTourByCar from './SameDayAgraTourByCar';
import SameDayAgraTourByTrain from './SameDayAgraTourByTrain';
import TajMahalSunriseTourByCar from './TajMahalSunriseTourByCar';
import DaysAgraOvernightTourByCar from './DaysAgraOvernightTourByCar';
import TourAndDelhiWalkingTour from './TourAndDelhiWalkingTour';
import TajMahalandAgraFortByLuxurycar from './TajMahalandAgraFortByLuxurycar';
import AgraSunsetBoatRideinYamunaRiver from './AgraSunsetBoatRideinYamunaRiver';
import AgraLocalCitySightseeingTour from './AgraLocalCitySightseeingTour';
import DelhiAgraMathuraTourDaysByCar from './DelhiAgraMathuraTourDaysByCar';
import FullDayOldDelhiandNewDelhiTour from './FullDayOldDelhiandNewDelhiTour';
import DelhiTemplesandSpiritualsSitesDayTour from './DelhiTemplesandSpiritualsSitesDayTour';
import DelhiArcheologicalSitesDayTour from './DelhiArcheologicalSitesDayTour';
import ChennaiwithReturnFlight from './ChennaiwithReturnFlight';
import HyderabadwithReturnFlight from './HyderabadwithReturnFlight';
import MumbaiwithReturnFlight from './MumbaiwithReturnFlight';
import JaipurTourByCarFromDelhi from './JaipurTourByCarFromDelhi';
import HaridwarandRishikeshTour from './HaridwarandRishikeshTour';
import VaranasiTourByFlight from './VaranasiTourByFlight';
import OvernightAgraTourFromDelhi from './OvernightAgraTourFromDelhi';
import OvernightJaipurTour from './OvernightJaipurTour';
import OvernightDelhiTour from './OvernightDelhiTour';
import ShimlaManaliTour from './ShimlaManaliTour';
import HimachalTourFromDelhi from './HimachalTourFromDelhi';
import HaridwarRishikeshMussoorieTour from './HaridwarRishikeshMussoorieTour';
import SameDayAgraTourbyCar from '../droppages/SameDayAgraTourbyCar';
import SameDayAgraTourTrain from '../droppages/SameDayAgraTourTrain';
import DaysAgraOvernight from '../droppages/DaysAgraOvernight';
import TajMahalSunrise from '../droppages/TajMahalSunrise';
import TajMahalandAgraFort from '../droppages/TajMahalandAgraFort';
import GoldenTriangleTour from '../droppages/GoldenTriangleTour';
import GoldenTriangleTourDayss from '../droppages/GoldenTriangleTourDayss';
import GoldenTriangleTourDaysss from '../droppages/GoldenTriangleTourDaysss';
import GoldenTriangleTourWithUdaipur from '../droppages/GoldenTriangleTourWithUdaipur';
import GoldenTriangleTourWithRanthambore from '../droppages/GoldenTriangleTourWithRanthambore';
import GoldenTriangleTourWithVaranasi from '../droppages/GoldenTriangleTourWithVaranasi';
import JaipurHoneymoonPackage from '../droppages/JaipurHoneymoonPackage';
import RajasthanFortsAndPalacesTours from '../droppages/RajasthanFortsAndPalacesTours';
import UdaipurAndJodhpurTour from '../droppages/UdaipurAndJodhpurTour';

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleNavItemClick = () => setIsNavCollapsed(true);

  return (
    <Router>
      <div>
        <ScrollToTop /> {/* Add the ScrollToTop component here */}
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-light py-0">
            <div className="container" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
              <Link className="navbar-brand" to="/" onClick={handleNavItemClick}>
                <span role="img" aria-label="Hello">
                  <img src={Salogo} alt="" />
                </span>
              </Link>
              <button className="navbar-toggler" type="button" onClick={handleNavCollapse} aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed} aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link active men-link ms-4" aria-current="page" to="/" onClick={handleNavItemClick} style={{color: 'black'}}>HOME</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link women-link ms-4" to="/about-us" onClick={handleNavItemClick} style={{color: 'black'}}>ABOUT US</Link>
                  </li>
                  <li className="nav-item nav ms-4">
                    <a className="nav-link nav-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: 'black'}}>TOUR PACKAGES
                     
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to="/same-day-tour" onClick={handleNavItemClick} style={{color: 'black'}}>SAME DAY TOUR</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/golden-triangle-tours" onClick={handleNavItemClick} style={{color: 'black'}}>GOLDEN TRIANGLE TOURS</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/rajasthan-tours" onClick={handleNavItemClick} style={{color: 'black'}}>RAJASTHAN TOURS</Link></li>
                      
                    
                    </ul>
                  </li>
                
                  <li className="nav-item">
                    <Link className="nav-link women-link ms-4" to="/plan-my-trip" onClick={handleNavItemClick} style={{color: 'black'}}>PLAN MY TRIP</Link>
                  </li>
                
                  <li className="nav-item">
                    <Link className="nav-link women-link ms-4" to="/contact-us" onClick={handleNavItemClick} style={{color: 'black'}}>CONTACT US</Link>
                  </li>
                  <button className="call-button">
                    <a rel="nofollow" className="component-target text-white" href="tel:+(91)-9837540138">
                      <i className="fa fa-phone"></i>
                      <span className="component-target-text">+(91)-9837540138</span>
                    </a>
                  </button>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div className="main-content"> {/* Added this wrapper div */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/same-day-agra-tour-by-car" element={<SameDayAgraTourByCar />} />
            <Route path="/same-day-agra-tour-by-train" element={<SameDayAgraTourByTrain />} />
            <Route path="/taj-Mahal-sunrise-tour-by-car" element={<TajMahalSunriseTourByCar/>}/>
            <Route path="/2-days-agra-overnight-tour-by-car" element={<DaysAgraOvernightTourByCar/>}/>
            <Route path="/taj-mahal-sunrise-tour-and-delhi-walking-tour" element={<TourAndDelhiWalkingTour/>}/>
            <Route path="/taj-mahal-and-agra-fort-by-luxury-car" element={<TajMahalandAgraFortByLuxurycar/>}/>
            <Route path="/agra-sunset-boat-ride-in-yamuna-river" element={<AgraSunsetBoatRideinYamunaRiver/>}/>
            <Route path="/agra-local-city-sightseeing-tour" element={<AgraLocalCitySightseeingTour/>}/>
            <Route path="/delhi-agra-mathura-tour-2-days-by-car" element={<DelhiAgraMathuraTourDaysByCar/>}/>
            <Route path="/full-day-old-delhi-and-new-delhi-tour" element={<FullDayOldDelhiandNewDelhiTour/>}/>
            <Route path="/delhi-template-and-spirituals-sites-day-tour" element={<DelhiTemplesandSpiritualsSitesDayTour/>}/>
            <Route path="/delhi-archeological-sites-day-tour" element={<DelhiArcheologicalSitesDayTour/>}/>
            <Route path="/chennai-with-return-flight" element={<ChennaiwithReturnFlight/>}/>
            <Route path="/hyderabad-with-return-flight" element={<HyderabadwithReturnFlight/>}/>
            <Route path="/mumbai-with-return-flight" element={<MumbaiwithReturnFlight/>}/>
            <Route path="/jaipur-tour-by-car-from-delhi" element={<JaipurTourByCarFromDelhi/>}/>
            <Route path="/haridwar-and-rishikesh-tour" element={<HaridwarandRishikeshTour/>}/>
            <Route path="/varansi-tour-by-flight" element={<VaranasiTourByFlight/>}/>
            <Route path="/overnight-agra-tour-from-delhi" element={<OvernightAgraTourFromDelhi/>}/>
            <Route path="/overnight-jaipur-tour" element={<OvernightJaipurTour/>}/>
            <Route path="/overnight-delhi-tour-by-car" element={<OvernightDelhiTour/>}/>
            <Route path="/shimla-manali-tour-from-delhi" element={<ShimlaManaliTour/>}/>
            <Route path="/himachal-tour-from-delhi" element={<HimachalTourFromDelhi/>}/>
            <Route path="/4-day-haridwar-rishikesh-mussoorie-tour" element={<HaridwarRishikeshMussoorieTour/>}/>

            <Route path="/about-us" element={<About />} />
            <Route path="/same-day-tour" element={<SameDayTour />} />
            <Route path="/same-day-agra-by-car" element={<SameDayAgraTourbyCar />} />
            <Route path="/same-day-agra-tour-train" element={<SameDayAgraTourTrain />} />
            <Route path="/days-agra-overnight-by-car" element={<DaysAgraOvernight />} />
            <Route path="/tajmahal-sunrise-tour-and-delhi-walking" element={<TajMahalSunrise />} />
            <Route path="/tajmahal-and-agra-fort-by-luxury-car" element={<TajMahalandAgraFort />} />

          
            
            <Route path="/golden-triangle-tours" element={<GoldenTriangleTours/>} />
            <Route path="/golden-triangle-tour-3-days" element={<GoldenTriangleTour/>} />
            <Route path="/golden-triangle-tour-4" element={<GoldenTriangleTourDayss/>} />
            <Route path="/golden-triangle-tour-5-days" element={<GoldenTriangleTourDaysss/>} />
            <Route path="/golden-triangle-tour-with-udaipur" element={<GoldenTriangleTourWithUdaipur/>} />
            <Route path="/golden-triangle-tour-with-ranthambore" element={<GoldenTriangleTourWithRanthambore/>} />
            <Route path="/golden-triangle-tour-with-varanasi" element={<GoldenTriangleTourWithVaranasi/>} />
            <Route path="/udaipur-and-jodhpur-tour" element={<UdaipurAndJodhpurTour/>} />

            
            
            <Route path="/rajasthan-tours" element={<RajasthanTours />} />
            <Route path="/jaipur-honeymoon-package" element={<JaipurHoneymoonPackage />} />
            <Route path="/rajasthan-fort-and-palaces-tours" element={<RajasthanFortsAndPalacesTours />} />

            
            <Route path="/plan-my-trip" element={<PlanMyTrip />} />
   
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default Navbar;
