import React, { useState } from 'react';
import Gold1 from "../components/img/Gold1.jpg";
import Gold2 from "../components/img/Gold2.jpg";
import Gold3 from "../components/img/Gold3.jpg";
import Gold4 from "../components/img/Gold4.jpg";
import Gold5 from "../components/img/Gold5.jpg";
import Gold6 from "../components/img/Gold6.jpg";
import { Link } from "react-router-dom";



const GoldenTriangleTourss = () => {
  const [hoveredLink, setHoveredLink] = useState(null);
  return (
    <div className="container-A py-3 px-4"><br /><h2><b>Golden Triangle Tours</b></h2><br /><br /><br />
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={Gold1} className="card-img-top" alt="" />
        <div className="card-body">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.0 Superb</p><br />
</div>
<Link
        to="/golden-triangle-tour-3-days"
        onMouseEnter={() => setHoveredLink('Golden')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Golden' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Golden' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start '><b>Golden Triangle Trip - 3 <br /> Days</b></h3>
      </Link>
        </div>
        {/* <div className="container-F">
      <div className="item">2 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br /> */}
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={Gold2} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 8.0 Superb</p><br />
</div>
<Link
        to="/golden-triangle-tour-4"
        onMouseEnter={() => setHoveredLink('Triangle')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Triangle' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Triangle' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start '><b>Golden Triangle Journey - 4 <br /> Days</b></h3>
      </Link>
        </div>
        {/* <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br /> */}
      </div>

    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={Gold3} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 8.0 Superb</p><br />
</div>
<Link
        to="/golden-triangle-tour-5-days"
        onMouseEnter={() => setHoveredLink('Tour')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Tour' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Tour' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start '><b>Golden Triangle Trip - 5 <br /> Days</b></h3>
      </Link>
        </div>
        {/* <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br /> */}
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={Gold4} className="card-img-top" alt="" />
        <div className="card-body">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 8.0 Superb</p><br />
</div>
<Link
        to="/golden-triangle-tour-with-udaipur"
        onMouseEnter={() => setHoveredLink('With')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'With' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'With' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start '><b>Golden Triangle Trip with <br /> Udaipur</b></h3>
      </Link>
        </div>
        {/* <div className="container-F">
      <div className="item">2 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br /> */}
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={Gold5} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/golden-triangle-tour-with-ranthambore"
        onMouseEnter={() => setHoveredLink('Ranthambore')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Ranthambore' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Ranthambore' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start '><b>Golden Triangle Trip with <br /> Ranthambore</b></h3>
      </Link>
        </div>
        {/* <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br /> */}
      </div>

    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={Gold6} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.0 Superb</p><br />
</div>
<Link
        to="/golden-triangle-tour-with-varanasi"
        onMouseEnter={() => setHoveredLink('Varanasi')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Varanasi' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Varanasi' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start '><b>Golden Triangle Trip with <br /> Varanasi</b></h3>
      </Link>
        </div>
        {/* <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br /> */}
      </div>
    </div>
  </div>

     
    </div>
  )
}

export default GoldenTriangleTourss
