import React from 'react'
import GoldenTriangleToursBanner from './GoldenTriangleToursBanner'
import GoldenTriangleTourss from './GoldenTriangleTourss'
import PlanStat from '../components/PlanStat'

const GoldenTriangleTours = () => {
  return (
    <div>
      <GoldenTriangleToursBanner/>
      <GoldenTriangleTourss/>
      <PlanStat/>
    </div>
  )
}

export default GoldenTriangleTours
