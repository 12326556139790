import React, { useState } from 'react';
import "./FeaturedTour.css";
import Featured1 from "./img/Featured1.jpg"
import Featured2 from "./img/Featured2.jpg"
import Featured3 from "./img/Featured3.jpg"
import Featured4 from "./img/Featured4.jpg"
import Featured5 from "./img/Featured5.jpg"
import Featured6 from "./img/Featured6.jpg"
import Featured7 from "./img/Featured7.jpg"
import Featured8 from "./img/Featured8.jpg"
import Featured9 from "./img/Featured9.jpg"
import Featured10 from "./img/Featured10.jpg"
import Featured11 from "./img/Featured11.jpg"
import Featured12 from "./img/Featured12.jpg"
import Featured13 from "./img/Featured13.jpg"
import Featured14 from "./img/Featured14.jpg"
import Featured15 from "./img/Featured15.jpg"
import Featured16 from "./img/Featured16.jpg"
import Featured17 from "./img/Featured17.jpg"
import Featured18 from "./img/Featured18.jpg"
import { Link } from "react-router-dom";


const FeaturedTour = () => {
  const [hover, setHover] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  return (
    <div><br /><br />
        <div className="style-F">
        <h6>Featured Tours</h6>
        </div>
        <h1>Same Day Tours</h1><br />
          <div className="container-A py-3 px-4">
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={Featured1} className="card-img-top"hi alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>


<Link
        to="/same-day-agra-tour-by-car"
        onMouseEnter={() => setHoveredLink('train')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'train' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'train' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>From Delhi : Agra Day Trip By Car</b></h3>
      </Link>        
        </div>
        <div className="container-F">
      <div className="item text-start">1 DAY</div>
      <div className="item text-center">12+</div>
      <div className="item text-end">AGRA</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={Featured2} className="card-img-top" alt="" />
        <div className="card-body">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.0 Superb</p><br />
</div>
<Link to="/same-day-agra-tour-by-train" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ textDecoration: 'none', color: hover ? '#7CA24A' : 'black', transform: hover ? 'scale(1.05)' : 'scale(1)', transition: 'all 0.3s ease', display: 'inline-block'}} > <h3 className='text-start ms-3'> <b>
From Delhi : Agra Day Trip By Train</b> </h3> </Link>

        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={Featured3} className="card-img-top" alt=" " />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 8.0 Superb</p><br />
</div>

<Link
        to="/taj-mahal-sunrise-tour-by-car"
        onMouseEnter={() => setHoveredLink('sunrise')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'sunrise' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'sunrise' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>From Delhi : Early Morning Taj Mahal Tour By Car</b></h3>
      </Link>        
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
  </div>
</div>
<div className="container-A py-3 px-4">
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={Featured4} className="card-img-top" alt="" />
        <div className="card-body">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 8.0 Superb</p><br />
</div>
<Link
        to="/2-days-agra-overnight-tour-by-car"
        onMouseEnter={() => setHoveredLink('overnight')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'overnight' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'overnight' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>From Delhi : 2-Day Agra Car Tour Overnight Stay</b></h3>
      </Link>        

        </div>
        <div className="container-F">
      <div className="item">2 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={Featured5} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 8.0 Superb</p><br />
</div>
<Link
        to="/taj-mahal-sunrise-tour-and-delhi-walking-tour"
        onMouseEnter={() => setHoveredLink('Walking')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Walking' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Walking' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>
        From Delhi : Taj Mahal Sunrise & Delhi Walking Experience</b></h3>
      </Link>        

        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>

    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={Featured6} className="card-img-top" alt=" " />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 8.0 Superb</p><br />
</div>
<Link
        to="/taj-mahal-and-agra-fort-by-luxury-car"
        onMouseEnter={() => setHoveredLink('luxury')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'luxury' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'luxury' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>From Delhi : Taj Mahal and Agra Fort Tour in a Luxury Car</b></h3>
      </Link>       

        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
  </div>
  
</div>
<div className="container-A py-3 px-4 ">
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={Featured7} className="card-img-top" alt="" />
        <div className="card-body">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/agra-sunset-boat-ride-in-yamuna-river"
        onMouseEnter={() => setHoveredLink('luxury')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'luxury' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'luxury' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>
        From Agra : Sunset Boat Ride on the Yamuna River</b></h3>
      </Link> 
        </div>
        <div className="container-F">
      <div className="item">30 MIN</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={Featured8} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.0 Superb</p><br />
</div>
<Link
        to="/agra-local-city-sightseeing-tour"
        onMouseEnter={() => setHoveredLink('city')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'city' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'city' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>From Agra : Local City Sightseeing Tour</b></h3>
      </Link> 

        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">AGRA</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={Featured9} className="card-img-top" alt=" " />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/delhi-agra-mathura-tour-2-days-by-car"
        onMouseEnter={() => setHoveredLink('mathura')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'mathura' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'mathura' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>
        From Delhi : 2-Day Delhi, Agra & Mathura Tour By Car</b></h3>
      </Link> 
        </div>
        <div className="container-F">
      <div className="item">2 DAY</div>
      <div className="item">12+</div>
      <div className="item">Delhi,Agra,Mathu</div>
    </div><br />
      </div>
    </div>
  </div>
  
</div>
<div className="container-A py-3 px-4">
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={Featured10} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/full-day-old-delhi-and-new-delhi-tour"
        onMouseEnter={() => setHoveredLink('old')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'old' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'old' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Full Day Tour of Old Delhi and New Delhi</b></h3>
      </Link> 
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">DELHI</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={Featured11} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/delhi-template-and-spirituals-sites-day-tour"
        onMouseEnter={() => setHoveredLink('sites')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'sites' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'sites' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Day Tour of Delhi's Temples and Spiritual Sites</b></h3>
      </Link> 
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">DELHI</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={Featured12} className="card-img-top" alt=" " />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/delhi-archeological-sites-day-tour"
        onMouseEnter={() => setHoveredLink('delhi')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'delhi' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'delhi' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Day Tour of Delhi’s Archaeological Sites</b></h3>
      </Link> 
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">DELHI</div>
    </div><br /><br />
      </div>
    </div>
  </div>
  
</div>
<div className="container-A py-3 px-4">
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={Featured13} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/chennai-with-return-flight"
        onMouseEnter={() => setHoveredLink('agra')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'agra' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'agra' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Day Trip to Agra from Chennai with Return Flight</b></h3>
      </Link>         </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">DELHI</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={Featured14} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/hyderabad-with-return-flight"
        onMouseEnter={() => setHoveredLink('Hyderabad')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Hyderabad' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Hyderabad' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>
        Day Trip to Agra from Hyderabad with Return Flight</b></h3>
      </Link>        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">DELHI</div>
    </div><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={Featured15} className="card-img-top" alt=" " />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/mumbai-with-return-flight"
        onMouseEnter={() => setHoveredLink('mumbai')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'mumbai' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'mumbai' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Day Trip to Agra from Mumbai with Return Flight</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">DELHI</div>
    </div><br /><br />
      </div>
    </div>
  </div>
  
</div>
<div className="container-A py-3 px-4 ">
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={Featured16} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/jaipur-tour-by-car-from-delhi"
        onMouseEnter={() => setHoveredLink('Jaipur')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Jaipur' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Jaipur' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Day Trip to Jaipur by Car from Delhi</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">DELHI</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={Featured17} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/haridwar-and-rishikesh-tour"
        onMouseEnter={() => setHoveredLink('Rishikesh')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Rishikesh' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Rishikesh' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Day Trip to Haridwar and Rishikesh by Car from Delhi</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">DELHI</div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={Featured18} className="card-img-top" alt=" " />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>
<Link
        to="/varansi-tour-by-flight"
        onMouseEnter={() => setHoveredLink('Varanasi')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Varanasi' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Varanasi' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>Day Trip to Varanasi by Flight from Delhi</b></h3>
      </Link>
        </div>
        <div className="container-F">
      <div className="item">1 DAY</div>
      <div className="item">12+</div>
      <div className="item">DELHI</div>
    </div><br /><br />
      </div>
    </div>
  </div>
  
</div>

    </div>
  )
}

export default FeaturedTour
