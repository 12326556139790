import React from 'react'
import SameDayTourBanner from './SameDayTourBanner'
import SameDayTourr from './SameDayTourr'
import PlanStat from '../components/PlanStat'

const SameDayTour = () => {
  return (
    
    <div >
<SameDayTourBanner/>
<SameDayTourr/>
<PlanStat/>

      </div>
  )
}

export default SameDayTour
