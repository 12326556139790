import React, { useState } from 'react';
import Over1 from "./img/Over1.jpg";
import Over2 from "./img/Over2.jpg";
import Over3 from "./img/Over3.jpg";
import { Link } from "react-router-dom";

const OverNight = () => {

  const [hoveredLink, setHoveredLink] = useState(null);
  return (
    <div><br /><br />
         <div className="style-F">
        <h6>Featured Tours</h6>
        </div>
        <h2>Overnight Tour Packages</h2><br />
          <div className="container-A py-3 px-4">
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 ">
      <div className="card-A">
        <img src={Over1} className="card-img-top" alt="" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>


<Link
        to="/overnight-agra-tour-from-delhi"
        onMouseEnter={() => setHoveredLink('Overnight')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'Overnight' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'Overnight' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>From Delhi: Agra Overnight Getaway</b></h3>
      </Link>          {/* <p className='text-start ms-0 '><b>Get compassionate and individualized care in your infertility matters from our devoted infertility care team. We are here to provide customized solutions with continuous support on your journey.</b></p> */}
        </div>
        <div className="container-F">
      <div className="item text-start">2 DAY</div>
      <div className="item text-center"></div>
      <div className="item text-end"></div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src={Over2} className="card-img-top" alt="" />
        <div className="card-body">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.0 Superb</p><br />
</div>
<Link
        to="/overnight-jaipur-tour"
        onMouseEnter={() => setHoveredLink('jaipur')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'jaipur' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'jaipur' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>From Delhi: Overnight Jaipur Trip by Car</b></h3>
      </Link>        </div>
        <div className="container-F">
      <div className="item">02 DAY</div>
      <div className="item"></div>
      <div className="item"></div>
    </div><br /><br />
      </div>
    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src={Over3} className="card-img-top" alt="Dr. Shweta Goswami" />
        <div className="card-body"><br />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
  <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i><p className='ms-2'> 9.5 Superb</p><br />
</div>

<Link
        to="/overnight-delhi-tour-by-car"
        onMouseEnter={() => setHoveredLink('tour')}
        onMouseLeave={() => setHoveredLink(null)}
        style={{
          textDecoration: 'none',
          color: hoveredLink === 'tour' ? '#7CA24A' : 'black',
          transform: hoveredLink === 'tour' ? 'scale(1.05)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: 'inline-block'
        }}
      >
        <h3 className='text-start ms-3'><b>From Delhi: Overnight Tour Around Delhi by Car</b></h3>
      </Link>        </div>
        <div className="container-F">
      <div className="item">02 DAY</div>
      <div className="item"></div>
      <div className="item"></div>
    </div><br /><br />
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default OverNight
