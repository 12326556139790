import React from 'react'
import ContacctForm from './ContacctForm';
import Train1 from "./img/Train1.jpg";
import Train2 from "./img/Train2.jpg";
import Train3 from "./img/Train3.jpg";

const SameDayAgraTourByTrain = () => {
  return (
    <div className="tour-details-container"><br />
 <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
       
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={Train1} className="d-block w-100 carousel-image" alt="..." />
            <div className="overlayy"></div>
            <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
             
            </div>
          </div>
          <div className="carousel-item">
            <img src={Train2} className="d-block w-100 carousel-image" alt="..." />
            <div className="overlayy"></div>
            <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
             
            </div>
          </div>
          <div className="carousel-item">
            <img src={Train3} className="d-block w-100 carousel-image" alt="..." />
            <div className="overlayy"></div>
            <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
             
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className="tour-details">
        <h2 className="tour-title">Agra Day Trip By Train</h2>
        <div className="tour-info-container">
          <div className="tour-info">
            <div className="info-item">
              <span className="icon">
                <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" viewBox="0 0 24 24">
                  <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
              </span>
              <span className="label">Duration<br />1 Day</span>
            </div>
            <div className="info-item">
              <span className="icon">
                <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" viewBox="0 0 24 24">
                  <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                </svg>
              </span>
              <span className="label">Min Age <br />12 +</span>
            </div>
            <div className="info-item">
              <span className="icon">
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="30"
                  fill="none"
                  viewBox="0 0 25 32"
                  style={{ fill: 'none', stroke: '#7CA24A', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round' }}
                >
                  <path d="M17.542,8.382L2.958,4.902l-1.25,1.26c-0.18,0.17-0.14,0.45,0.07,0.58l11.083,6.465"/>
                  <path d="M17.884,17.581l7.374,12.642c0.13,0.209,0.41,0.25,0.58,0.07l1.26-1.25l-3.903-16.359"/>
                  <path d="M9.134,19.857l-6.336-0.715l-1.19,1.189c-0.18,0.18-0.13,0.48,0.09,0.6l3.787,1.975"/>
                  <path d="M8.109,24.625l2.958,5.677c0.12,0.221,0.42,0.271,0.6,0.091l1.19-1.19l-0.715-6.333"/>
                  <path d="M7.328,24.673l0.4-0.011c0.12-0.01,2.81-0.14,4.88-2.22c0.63-0.58,14.51-13.32,15.99-14.811c2.2-2.2,2.15-5.149,1.54-5.77c-0.61-0.61-3.58-0.66-5.77,1.54c-1.5,1.5-14.23,15.359-14.82,16c-0.644,0.649-1.104,1.354-1.43,2.024"/>
                  <line x1="10.5" x2="4" y1="21.5" y2="28"/>
                  <path d="M27.498,3.502c0.552,0,1,0.448,1,1"/>
                </svg>
              </span>
              <span className="label text-start">Tour Type<br />Safari </span>
            </div>
            <div className="info-item">
              <span className="icon">
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="none"
                  viewBox="0 0 20 20"
                  style={{ fill: '#7CA24A', fillRule: 'evenodd', clipRule: 'evenodd' }}
                >
                  <path d="M9.3,19.7C8.5,18.9,2,12.2,2,8c0-4.4,3.6-8,8-8s8,3.6,8,8c0,4.2-6.5,10.9-7.3,11.7C10.3,20.1,9.7,20.1,9.3,19.7z M10,2 C6.7,2,4,4.7,4,8c0,2.5,3.8,7.2,6,9.5c2.2-2.3,6-7,6-9.5C16,4.7,13.3,2,10,2z"/>
                  <circle cx="10" cy="8" r="2"/>
                </svg>
              </span>
              <span className="label">Location <br />Agra</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <br />
        {/* Any additional content you add here will not have the background color */}

        <p className='text-start texp ms-4'> <svg className="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={25} height={30} fill="none" viewBox="0 0 24 24">
                  <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg> Posted 2 days ago |  <i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i> <i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1 " aria-hidden="true" style={{ color: 'gold' }}> </i>  9.0 Superb </p>
      </div><hr />
      <div className="overview-contact-wrapper">
        <div className="overview-container">
          <h1 className="overvieyw-title text-start ms-3">Overview</h1>
          <p className="overview-text text-start ms-3"><br /><br />
          The Taj Mahal is one of India's most renowned monuments, crafted from white marble by Mughal Emperor Shah Jahan in memory of his wife Mumtaz Mahal. Located in Agra, Uttar Pradesh, it draws over six million visitors annually. A visit to the Taj Mahal is a must for anyone traveling to India. As one of the Seven Wonders of the World, it attracts about 20% of international tourists to India. While you can explore Agra by bus or taxi, for a truly authentic experience, consider a train ride. Begin your Agra Day Trip by Train.</p> <div className="include-exclude-wrapper">
      <h2 className='text-start'>Include/Exclude</h2><br />
      <div className="include-exclude-container">
        <ul className="include-list">
          <li>
            <span role="img" aria-label="Check">✅</span>
            1 Meal Per Day
          </li>
          <li>
            <span role="img" aria-label="Check">✅</span>
            Govt. Approved Tour guide
          </li>
          <li>
            <span role="img" aria-label="Check">✅</span>
            Monument Entrances Fees
          </li>
          {/* <li>
            <span role="img" aria-label="Check">✅</span>Visit 7 Best Places in the City With Group
          </li> */}
        </ul>
        <ul className="exclude-list">
          <li>
            <span role="img" aria-label="Cross">❌</span>
            Additional Services
          </li>
          <li>
            <span role="img" aria-label="Cross">❌</span>
            Insurance
          </li>
          <li>
            <span role="img" aria-label="Cross">❌</span>
            Food & Drinks
          </li>
          <li>
            <span role="img" aria-label="Cross">❌</span>
            Tickets
          </li>
        </ul>
      </div>
      <hr /><br /><br /><h2 className='text-start ms-2'>Tour Plan</h2>
      <div className="text-box"><br />
        <h4 className='text-start ms-3'>07:00 a.m. - Departure from Delhi</h4>
        <p className='text-start ms-3'>At 07:00 a.m.- our driver will pick you up from your Delhi hotel and drop you off at the H Nizamuddin Railway Station. Confirm in and take your seat. Your train will depart from H Nizamuddin Railway Station at 08:10 a.m. and arrive at Agra Cantt Railway Station at 09:50 a.m.</p>
       <br /><br /> <h4 className='text-start ms-3'>10:00 a.m. - Agra Cantt</h4>
       <p className='text-start ms-3'>Our motorist will meet you at Agra Cantt Railway Station with your name on board and take you towards the Taj Mahal.</p>
       <br /><br /> <h4 className='text-start ms-3'>10:30 a.m. - Taj Mahal</h4>
       <p className='text-start ms-3'>Now since you arrive at your preliminary step, our Tourist Guide will meet you there and clarify the full-day itinerary of Agra City. And after that, pay a visit to the Taj Mahal, which was built by Mughal Emperor Shah Jahan in memory of his wife, Arjumand Banu Begum.</p>
       <br /><br /> <h4 className='text-start ms-3'>12.30 p.m. - Agra Fort</h4>
       <p className='text-start ms-3'>The Brilliant Reign Of sultan Akbar crafted Agra Fort in 1565 A.D. Our guide explains you all about this place.</p>
       <br /><br /> <h4 className='text-start ms-3'>14:00 p.m. - Lunch</h4>
       <p className='text-start ms-3'>Following a visit to the Agra Fort, It's time to eat lunch at a highly regarded steakhouse and sample Mughal recipes.</p>
       <br /><br /> <h4 className='text-start ms-3'>15:00 p.m. - Baby Taj</h4>
       <p className='text-start ms-3'>It's time to go and see Baby Taj one that our tourist guide describes in detail. It's optional visit.</p>
       <br /><br /> <h4 className='text-start ms-3'>16:00 p.m. - Mehtab Bagh</h4>
       <p className='text-start ms-3'>Upon having visited the Baby Taj simply continue to Mehtab Bagh, from where you would see the Taj Mahal's backside with the River Yamuna. Take some snaps to retain your memorable moments.</p>
       <br /><br /> <h4 className='text-start ms-3'>17:00 p.m. - Departure from Agra Cantt Railway Station</h4>
       <p className='text-start ms-3'>Upon having visited Agra, it's the moment to say good-bye with fantastic memories. Our motorist will drop you off at Agra Cantt Railway Station. There from, board your train, which departs at 17:50 p.m. and arrives at H Nizamuddin Railway Station at 19:30 p.m. Our motorist will therefore grant you access from the station and drop you off towards your hotel. The tour concludes here with wonderful memories.</p>
      </div>
      
    </div>
    
        </div>
        <div className="contact-form-wrapper">
          <ContacctForm />
        </div>
        
      </div>
     
    </div>
  )
}

export default SameDayAgraTourByTrain
