import React from 'react'
import Fort1 from "../components/img/fort1.jpg";
import Fort2 from "../components/img/fort2.jpg";
import Fort3 from "../components/img/fort3.jpg";
const RajasthanToursBanner = () => {
  return (
    <div>
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      {/* <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div> */}
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={Fort2} className="d-block w-100 carousel-image" alt="..." />
          <div className="overlay"></div>
          <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
          <div>
             <h1 className="styled-h1 text-center"> Khamma Ghani 🙏</h1> <br />
            </div>
            <p className='text-center ms-2'>Welcome to Rajasthan Tours . Now travel in rajasthan</p>
          </div>
        </div>
        <div className="carousel-item">
          <img src={Fort1} className="d-block w-100 carousel-image" alt="..." />
          <div className="overlay"></div>
          <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
          {/* <div>
             <h1 className="styled-h1 text-center"> Welcome Ji 😉 !!!</h1> <br />
            </div>
            <p>Some representative placeholder content for the second slide.</p> */}
          </div>
        </div>
        <div className="carousel-item">
          <img src={Fort3} className="d-block w-100 carousel-image" alt="..." />
          <div className="overlay"></div>
          <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
          {/* <div>
             <h1 className="styled-h1 text-center"> Welcome Ji 😉 !!!</h1> <br />
            </div>
            <p>Some representative placeholder content for the third slide.</p> */}
          </div>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  )
}

export default RajasthanToursBanner
