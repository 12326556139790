import React from 'react';
import './Banner.css';
import bannerimage1 from "../components/img/bannerimage1.jpg";
import bannerimage2 from "../components/img/bannerimage2.jpg";
import bannerimage3 from "../components/img/bannerimage3.jpg";

const Banner = () => {
  
  return (
    <div>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        {/* <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> */}
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={bannerimage1} className="d-block w-100 carousel-image" alt="..." />
            <div className="overlay"></div>
            <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
            <div>
             <h1 className="styled-h1 text-center"> Welcome Ji 😉 !!!</h1> <br />
            </div>
              <div>
              <p className='styled-p ms-1' >Experience the Taj Mahal with Budget Taj <br /> Tour—exclusive and affordable. Our friendly <br /> team guarantees a memorable journey <br /> within your budget! So let's Go 👍</p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={bannerimage2} className="d-block w-100 carousel-image" alt="..." />
            <div className="overlay"></div>
            <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
            <div>
             <h1 className="styled-h1 ms-1">Chale Ghumane 😎</h1> <br />
            </div>
              <p className='styled-p ms-2'>Travel with Budget Taj Tour for a memorable <br /> Taj Mahal experience. Our knowledgeable <br /> guides and friendly team .Create lasting <br /> memories with us! 👍.......</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={bannerimage3} className="d-block w-100 carousel-image" alt="..." />
            <div className="overlay"></div>
            <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
            <div>
             <h1 className="styled-h1">Explore Package🎁</h1> <br />
            </div>
              <p className='styled-p'>Discover the Taj Mahal with our Explore <br /> Package. Enjoy expert guides, comfortable <br /> stays, and personalized service, all at an <br /> affordable price. Experience the magic of the <br />Taj Mahal with ease 😊......</p>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Banner;
