import React from 'react'
import RajasthanToursBanner from './RajasthanToursBanner'
import RajasthanTourss from './RajasthanTourss'

const RajasthanTours = () => {
  return (
    <div>
      <RajasthanToursBanner/>
      <RajasthanTourss/>
    </div>
  )
}

export default RajasthanTours
